import React from "react";
import {Link} from "react-router-dom";
import InfoPlates from "./components/InfoPlates/InfoPlates";
import Projects from "./components/ProjectsList/ProjectList";

export default function ({about, projects}) {
    const featuredProjects = projects.original.filter(project => project.featured);
    return (
        <div className="page home">
            <div className="home__hero hero">
                <div className="hero__greet">
                    <div className="hero-greet">
                        <h1 className="hero-greet__h1 page__heading">
                            <span className="hero-greet__main hero-h1__part">HI, MY NAME IS CHRISTIAN GUNVALDSEN</span>
                            <span className="hero-greet__sub hero-h1__part">I AM AN ASPIRING FRONTEND-DEVELOPER</span>
                            <span className="hero-greet__welcome hero-h1__part">WELCOME TO MY PORTFOLIO</span>
                        </h1>
                    </div>
                </div>
                <InfoPlates skills={about.cards}/>
            </div>
            <div className="home__projects">
                <h2 className="home-projects__heading page__heading">FEATURED PROJECTS</h2>
                <Projects projects={featuredProjects}/>
                <Link className="home-projects__more btn" to="/projects">See All Projects</Link>
            </div>
        </div>
        //<span>Photo by Daniel Leone on Unsplash</span>
    )
}