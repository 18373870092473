import React from "react";
import Checkbox from "../../../../global/checkbox";
import RadioButton from "../../../../global/radioButton";

export default function ({item, filters, type="checkbox"}) {
    return ( //add click function
        <div className="projects-filter__item">
            <label className="filter-item">
                <input name={item.content} className="filter-item__toggle" value={JSON.stringify(item)} type="checkbox"/>
                <span className={`filter-item filter-item__${item.identifier}`}>
                    {type === "checkbox" && (
                        <Checkbox state={item.active} label={item.content}/>
                    )}
                    {type === "radioButton" && (
                        <RadioButton state={item.active} label={item.content}/>
                    )}
                </span>
            </label>
        </div>
    )
}