import React, {useState} from "react";
import {NavLink, useLocation} from "react-router-dom";

const logoW = "/media/images/logo-white.png";

export default function () {
    const [mobile, setMobile] = useState(false);
    const {pathname} = useLocation();
    console.log(pathname);
    function checkPathName() {
        if (pathname === "/projects") {
            return "--inverted";
        } else {
            return "";
        }
    }
    const extension = checkPathName();

    if (mobile) {
        return (
            <nav className="nav--mobile">
                <NavLink className="nav__logo" to="/" exact>
                    <img className="nav-logo__item" src={logoW} alt="my logo"/>
                </NavLink>
                <button className="nav__menuToggle" onClick={() => setMobile(false)}>
                    <i className="fas fa-bars"/>
                </button>
                <div className="nav__links" onClick={() => setMobile(false)}>
                    <NavLink activeClassName="nav__link--active" className={`nav__link--home nav__link--mobile nav__link${extension}`} to="/" exact>Home</NavLink>
                    <NavLink activeClassName="nav__link--active" className={`nav__link--projects nav__link--mobile nav__link${extension}`} to="/projects">Projects</NavLink>
                    <NavLink activeClassName="nav__link--active" className={`nav__link--about nav__link--mobile nav__link${extension}`} to="/aboutme">About Me</NavLink>
                </div>
            </nav>
        )
    } else {
        return (
            <nav className="nav">
                <div className="nav__elements">
                    <NavLink className="nav__logo" to="/" exact>
                        <img className="nav-logo__item" src={logoW} alt="my logo"/>
                    </NavLink>
                    <button className="nav__menuToggle" onClick={() => setMobile(true)}>
                        <i className="fas fa-bars"/>
                    </button>
                </div>
                <div className="nav__links">
                    <NavLink activeClassName="nav__link--active" className={`nav__link--home nav__link${extension}`} to="/" exact>Home</NavLink>
                    <NavLink activeClassName="nav__link--active" className={`nav__link--projects nav__link${extension}`} to="/projects">Projects</NavLink>
                    <NavLink activeClassName="nav__link--active" className={`nav__link--about nav__link${extension}`} to="/aboutme">About Me</NavLink>
                </div>
            </nav>
        )
    }

}