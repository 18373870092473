import React from "react";

export default function BuildPage (props) {
    return (
        <div className="build">
            <div className="build__display">

            </div>
            <div className="build__code">
                
            </div>
        </div>

    )
}