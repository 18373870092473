import React, {useState} from "react";
import ProjectTags from "./components/ProjectTags";

export default function ({project}) {
    const [show, setShow] = useState(false);
    function setDisplay(show) {
        project.highlighted = show;
        setShow(show);
    }
    if (show) {
        return (
            <li className="home-projects__display col-t-6 col-m-6">
                <a className="home-display home-display--active" href={project.link} target="_blank" rel="noopener noreferrer" onMouseEnter={() => setDisplay(true)} onMouseLeave={() => setDisplay(false)}>
                    <span className="home-display__click">Click to se project</span>
                    <img className="home-display__img home-display__img--faded img" src={project.screenshot} alt={project.name}/>
                    <h3 className="home-display__name">{project.name}</h3>
                    <ProjectTags show={show} tags={project.tags}/>
                </a>
            </li>
        )
    } else {
        return (
            <li className="home-projects__display col-t-6 col-m-6">
                <a className="home-display" href={project.link} target="_blank" rel="noopener noreferrer" onMouseEnter={() => setDisplay(true)} onMouseLeave={() => setDisplay(false)}>
                    <span className="home-display__click home-display__click--hidden">{"<< Click to see project >>"}</span>
                    <img className="home-display__img project-img img" src={project.screenshot} alt={project.name}/>
                    <h3 className="home-display__name home-display__name--hidden">{project.name}</h3>
                    <ProjectTags show={show} tags={project.tags}/>
                </a>
            </li>
        )
    }
}