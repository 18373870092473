import React from "react";
import ProjectItem from "./components/ProjectItem/ProjectItem";

export default function ({projects}) {
    //console.log(projects);
    return (
        <ul className="home-projects__list row">
            {projects.map(project => <ProjectItem key={project.key} project={project}/>)}
        </ul>
    )
}