import React, {useState} from "react";
import ProjectFilter from "./components/ProjectFilter/ProjectFilter";
import ProjectList from "./components/ProjectList/ProjectList";

export default function ({projects, filters:filter}) {
    const [data, setData] = useState(projects);
    const [filters, setFilters] = useState(filter);

    function _filterProjects(event) {
        event.persist();
        const target = JSON.parse(event.target.value);

        setFilters(newUpdateFilters())
        const newFilters = newUpdateFilters();
        function newUpdateFilters() {
            //update featured
            if (target.type === "featured") {
                return {
                    ...filters,
                    featured: filters.featured.map(display => {
                        //toggles between the options and updates the active state
                        return {
                            ...display,
                            active: display.key === target.key
                        };
                    })
                }
            }

            //update types
            if (target.type === "type") {
                return {
                    ...filters,
                    type: filters.type.map(type => {
                        //toggles clicked option and changes the active state.
                        if (type.key === target.key) {
                            return {
                                ...type,
                                active: !type.active
                            };
                        } else { //if not clicked target, returns category unchanged.
                            return type;
                        }
                    })
                }
            }

            //update Categories
            if (target.type === "category") {
                return {
                    ...filters,
                    categories: filters.categories.map(category => {
                        //toggles clicked option and changes the active state.
                        if (category.key === target.key) {
                            return {
                                ...category,
                                active: !category.active
                            };
                        } else { //if not clicked target, returns category unchanged.
                            return category;
                        }
                    })
                }
            }

            //update Dates

            if (target.type === "date") {
                return {
                    ...filters,
                    date: filters.date.map(date => {
                        //toggles clicked option and changes the active state.
                        if (date.key === target.key) {
                            return {
                                ...date,
                                active: !date.active
                            };
                        } else { //if not clicked target, returns category unchanged.
                            return date;
                        }
                    })
                }
            }
        }

        filter(newFilters);
        //console.log("activeFilters: ", temp_activeFilters ,"filteredProjects", filteredProjects);
        function filter (filters) {

            //filterFunction for feature
            function checkFeatured(data) {
                let test = true;
                //loops trough all the features
                for (let display of filters.featured) {
                    //checks if featured is active and checks if project is featured.
                    if (display.key === "10d48262-aaca-489c-8947-7a954ba51883" && display.active) {
                        test = (data.featured);
                    }
                }
                //returns the answer from check
                return test;
            }

            //filterFunction for types
            function checkType(date) {
                let test = true;
                //loops trough all the types
                for (let type of filters.type) {
                    //checks if type is active and sets (test to false) before test
                    if (type.active) {
                        test = false;
                        //if match is found set (test to true) and then beak out off loop
                        if (type.value === date.type) {
                            test = true;
                            break;
                        }
                    }
                }
                //returns the answer from check
                return test;
            }
    
            //filterFunction for categories
            function checkCategories(data) {
                let test = true;
                //loops trough all the categories
                for (let category of filters.categories) {
                    //checks if category is active then sets (test to false) before test
                    if (category.active) {
                        test = false;
                        //loops trough every tag of the project and looks if it matches
                        for (let tag of data.tags) {
                            //checks if more tags connected to filter and loops trough the array
                            if (Array.isArray(category.value)) {
                                for (let value of category.value) {
                                    //if match is found set (test to true) and then beak out off loop
                                    if (tag.key === value.key) {
                                        test = true;
                                        break;
                                    }
                                }
                            } else if (tag.key === category.key) {
                                test = true;
                                break;
                            }
                        }
                        //if test finds one match break out of entire loop
                        if (test === true) {
                            break;
                        }
                    }
                }
                //returns the answer from check
                return test;
            }

            //filterFunction for date
            function checkDates(data) {
                let test = true;
                //loops trough all the dates
                for (let date of filters.date) {
                    //checks if category is active then sets test to false by default
                    if (date.active) {
                        test = false;
                        //if match is found set (test to true) and then beak out off loop
                        if (data.year === date.value) {
                            test = true;
                            break;
                        }
                    }
                }
                return test;
            }
    
            setData({
                ...data,
                operator: data.original.filter(project => {
                    return (checkFeatured(project) && checkType(project) && checkCategories(project) && checkDates(project));
                })
            });
        }

        //function to check if filter for featured is active
    }
    return (
        <div className="page projects row">
            <ProjectFilter filterF={_filterProjects} filters={filters}/>
            <main className="projects__list col-d-9">
                <h1 className="projects__h1 page__heading">MY PROJECTS</h1>
                <ProjectList projects={data.operator}/>
            </main>
        </div>
    )
}