import React from "react";
import ProjectTags from "./components/ProjectTags";

export default function ({project, odd}) {
    if (odd) {
        return (
            <li className="projects__display--odd">
                <div className="projects-display row">
                    <div className="projects-display__info col-12 col-d-6">
                        <h2 className="projects-info__name projects__heading--odd">{project.name}</h2>
                        <ProjectTags odd={true} tags={project.tags}/>
                        {project.brief.map(paragraph => <p className="project-info__body project__content-odd paragraph" key={paragraph.key}>{paragraph.content}</p>)}
                    </div>
                    <div className="projects-display__showcase col-12 col-d-6">
                        <a className="project-showcase" href={project.link} target="_blank" rel="noopener noreferrer">
                            <img className="project-showcase__img" src={project.showcase} alt="An showcase of project on both laptop and mobile"/>
                        </a>
                    </div>
                    <a className="projects-display__btn btn--inverted" href={project.link} target="_blank" rel="noopener noreferrer">Visit project</a>
                </div>
            </li>
        )
    } else {
        return (
            <li className="projects__display--even">
                <div className="projects-display row">
                    <div className="projects-display__info col-12 col-d-6">
                        <h2 className="projects-info__name projects__heading--even">{project.name}</h2>
                        <ProjectTags odd={false} tags={project.tags}/>
                        {project.brief.map(paragraph => <p className="project-info__body project__content-even paragraph" key={paragraph.key}>{paragraph.content}</p>)}
                    </div>
                    <div className="projects-display__showcase col-12 col-d-6">
                        <a className="project-showcase" href={project.link} target="_blank" rel="noopener noreferrer">
                            <img className="project-showcase__img" src={project.showcase} alt="An showcase of project on both laptop and mobile"/>
                        </a>
                    </div>
                    <a className="projects-display__btn btn--inverted" href={project.link} target="_blank" rel="noopener noreferrer">Visit project</a>
                </div>
            </li>
        )
    }
}