import React from "react";
import ItemList from "./ItemList/ItemList";

export default function ({item}) {
    return (
        <div className="info-plates__card info-card column">
            <h2 className="card__name">{item.heading}</h2>
            <div className="card__content">
                {item.sections.map(section => <ItemList key={section.key} item={section}/>)}
            </div>
        </div>
    )
}