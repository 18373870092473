import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export default function () {
    const [contact, setContact] = useState({});
    const [valid, setValid] = useState(false);
    const [duplicateMessage, setDuplicateMessage] = useState(false);
    function _sendForm(data) {
        console.log("comparing", JSON.stringify(contact) === JSON.stringify(data));
        if (JSON.stringify(contact) !== JSON.stringify(data)) { //work around due to shallow compare
            setContact({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                context: data.context,
                message: data.message
            });
            setValid(true);
            const formData = {
                from_name: data.firstName + " " + data.lastName,
                subject: data.context,
                reply_to: data.email,
                message_html: data.message
            };
            console.log("accepted");
            window.emailjs.send('service_y6e3jhj', "template_6t6eg1s", formData)
                .then(res => console.log('Email Successfully sent!'))
                .catch(err => console.error("email not sent, error"))
            return true;
        } else {
            setDuplicateMessage(true);
            return false
        }
    }
    const { register, handleSubmit, errors } = useForm({
        validationSchema: yup.object().shape({
            firstName: yup
                .string()
                .matches(/[\w\s-]+/, {
                    message: "First name is not a valid name",
                    excludeEmptyString: true
                })
                .required("First name empty, Please fill in your First name"),
            lastName: yup
                .string()
                .matches(/[\w\s-]+/, {
                    message: "Last name is not a valid name",
                    excludeEmptyString: true
                })
                .required("Last name empty, Please fill in your Last name"),
            email: yup
                .string()
                .email("Email is not a valid email, please make sure you entered correct")
                .required("Email is empty, Please fill in your email so that we can contact you"),
            context: yup
                .string()
                .matches(/^(?!placeholder).*$/, {message:"Option not Selected Please select one of the options above"})
                .required(""),
            message: yup
                .string()
                .min(10, "the message is to short, please enter a more descriptive message")
                .required("Please enter a message"),
        })
    });

    return (
        <aside className="page__contact">
            <h2 className="page__heading">CONTACT ME HERE</h2>
            <form className="contact__form" onSubmit={handleSubmit(_sendForm)}>
                <div className="form__section row">
                    <div className="form__firstName form__group col-6 col-m-12">
                        <label className="form-firstName__label form__label" htmlFor="firstName">First name</label>
                        <input className="form-firstName__input form__item form__input" name="firstName" type="text" placeholder="First name" ref={register}/>
                        {errors.firstName && <p className="form__error">{errors.firstName.message}</p>}
                    </div>
                    <div className="form__lastName form__group col-6 col-m-12">
                        <label className="form-lastName__label form__label" htmlFor="surname">Last name</label>
                        <input className="form-lastName__input form__item form__input" name="lastName" type="text" placeholder="Last name" ref={register}/>
                        {errors.lastName && <p className="form__error">{errors.lastName.message}</p>}
                    </div>
                </div>
                <div className="form__email form__group">
                    <label className="form-email__label form__label" htmlFor="email">Email address</label>
                    <input className="form-email__input form__item form__input" name="email" type="email" placeholder="example@example.com" ref={register}/>
                    {errors.email && <p className="form__error">{errors.email.message}</p>}
                </div>
                <div className="form__context form__group">
                    <label className="form-context__label form__label" htmlFor="context">Select context</label>
                    <select className="form-context__input form__item form__select" name="context" ref={register}>
                        <option className="form__option" value="placeholder" hidden defaultValue>Please select one of the options...</option>
                        <option className="form__option" value="jobOffer">Job offer</option>
                        <option className="form__option" value="hire">Hire me</option>
                        <option className="form__option" value="questions">Questions about me</option>
                        <option className="form__option" value="other">Other</option>
                    </select>
                    {errors.context && <p className="form__error">{errors.context.message}</p>}
                </div>
                <div className="form__message form__group">
                    <label className="form-message__label form__label" htmlFor="message">Message</label>
                    <textarea className="form-message__input form__item form__textArea" name="message" placeholder="Please enter your message here..." ref={register}/>
                    {errors.message && <p className="form__error">{errors.message.message}</p>}
                </div>
                <button className="form__submit btn" type="submit">Send</button>
                {duplicateMessage && (<p className="form__error">This message have already been sent</p>)}
            {valid === true && (
                <div className="contact__prompt">
                    <div className="contact-prompt">
                        <div className="contact-prompt__topBar">
                            <button className="contact-prompt__close" onClick={() => setValid(false)}>X</button>
                        </div>
                        <div className="contact-prompt__content">
                            <span className="prompt-content__main">Thank you for contacting me, </span>
                            <span className="prompt-content__sec">I will reach out to you as soon as possible</span>
                        </div>
                        <button className="contact-prompt__ok btn" onClick={() => setValid(false)}>Ok</button>
                    </div>
                </div>
            )}
            </form>
        </aside>
    )
}