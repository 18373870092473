import React from "react";

export default function checkbox({state, label}) {
    if (state === true) {
        return (
            <>
                <i className="far fa-check-square form__checkbox"/>
                {label}
            </>
        )
    } else {
        return (
            <>
                <i className="far fa-square form__checkbox"/>
                {label}
            </>
        )
    }
}